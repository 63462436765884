<template>
	<section class="home col" align-x="left">
		<p>首頁 Banner</p>
		<el-card>
			<div class="row" gutter="10">
				<div>
					<upload v-model="banners1" media-folder="home" multiple :disabled="!checkEditable('banners1')" />
				</div>
				<div class="row" align-self="bottom">
					<template v-if="checkEditable('banners1')">
						<el-button @click="handleCancel('banners1')" type="text">取消</el-button>
						<el-button @click="handleSave('banners1')" type="success">儲存</el-button>
					</template>
					<template v-else>
						<el-button @click="handleEdit('banners1')" type="text" icon="el-icon-edit">編輯</el-button>
					</template>
				</div>
			</div>
		</el-card>
		<br>
		<p>首頁 Banner 手機版</p>
		<el-card>
			<div class="row" gutter="10">
				<div>
					<upload v-model="banners1_mobile" media-folder="home" multiple :disabled="!checkEditable('banners1_mobile')" />
				</div>
				<div class="row" align-self="bottom">
					<template v-if="checkEditable('banners1_mobile')">
						<el-button @click="handleCancel('banners1_mobile')" type="text">取消</el-button>
						<el-button @click="handleSave('banners1_mobile')" type="success">儲存</el-button>
					</template>
					<template v-else>
						<el-button @click="handleEdit('banners1_mobile')" type="text" icon="el-icon-edit">編輯</el-button>
					</template>
				</div>
			</div>
		</el-card>
		<br>
		<p>第二區 Banner</p>
		<el-card>
			<div class="row" gutter="10">
				<div>
					<upload v-model="banners2" media-folder="home" multiple :disabled="!checkEditable('banners2')" />
				</div>
				<div class="row" align-self="bottom">
					<template v-if="checkEditable('banners2')">
						<el-button @click="handleCancel('banners2')" type="text">取消</el-button>
						<el-button @click="handleSave('banners2')" type="success">儲存</el-button>
					</template>
					<template v-else>
						<el-button @click="handleEdit('banners2')" type="text" icon="el-icon-edit">編輯</el-button>
					</template>
				</div>
			</div>
		</el-card>
		<br>
		<p>第二區 Banner 手機版</p>
		<el-card>
			<div class="row" gutter="10">
				<div>
					<upload v-model="banners2_mobile" media-folder="home" multiple :disabled="!checkEditable('banners2_mobile')" />
				</div>
				<div class="row" align-self="bottom">
					<template v-if="checkEditable('banners2_mobile')">
						<el-button @click="handleCancel('banners2_mobile')" type="text">取消</el-button>
						<el-button @click="handleSave('banners2_mobile')" type="success">儲存</el-button>
					</template>
					<template v-else>
						<el-button @click="handleEdit('banners2_mobile')" type="text" icon="el-icon-edit">編輯</el-button>
					</template>
				</div>
			</div>
		</el-card>
		<br>
		<p>第三區 Banner</p>
		<el-card>
			<div class="row" gutter="10">
				<div>
					<upload v-model="banners3" media-folder="home" multiple :disabled="!checkEditable('banners3')" />
				</div>
				<div class="row" align-self="bottom">
					<template v-if="checkEditable('banners3')">
						<el-button @click="handleCancel('banners3')" type="text">取消</el-button>
						<el-button @click="handleSave('banners3')" type="success">儲存</el-button>
					</template>
					<template v-else>
						<el-button @click="handleEdit('banners3')" type="text" icon="el-icon-edit">編輯</el-button>
					</template>
				</div>
			</div>
		</el-card>
		<br>
		<p>第三區 Banner 手機版</p>
		<el-card>
			<div class="row" gutter="10">
				<div>
					<upload v-model="banners3_mobile" media-folder="home" multiple :disabled="!checkEditable('banners3_mobile')" />
				</div>
				<div class="row" align-self="bottom">
					<template v-if="checkEditable('banners3_mobile')">
						<el-button @click="handleCancel('banners3_mobile')" type="text">取消</el-button>
						<el-button @click="handleSave('banners3_mobile')" type="success">儲存</el-button>
					</template>
					<template v-else>
						<el-button @click="handleEdit('banners3_mobile')" type="text" icon="el-icon-edit">編輯</el-button>
					</template>
				</div>
			</div>
		</el-card>
		<br>
		<p>金枝年表</p>
		<el-popover placement="top" width="200" v-model="popoverVisible1">
			<el-input v-model="tabToAdd" placeholder="西元" />
			<br>
			<div class="row" align-x="right" style="margin-top:1em;">
				<el-button type="text" size="mini" @click="handleCancelAddTab()">取消</el-button>
				<el-button type="primary" size="mini" @click="handleAddTab(tabToAdd)">新增</el-button>
			</div>
			<el-button slot="reference" type="warning">新增年份範圍</el-button>
		</el-popover>
		<br>
		<el-tabs v-model="currentTab" type="border-card" closable @tab-remove="handleRemoveTab">
			<el-tab-pane v-for="rangeKey in Object.keys(timeline).sort()" :key="rangeKey" :label="rangeKey" :name="rangeKey">
				<div class="row-grid" gutter-x="30" gutter-y="20">
					<div v-for="(item, i) in timeline[rangeKey]" :key="i">
						<el-card>
							<div class="row" gutter="20">
								<el-form ref="ruleForm" :model="item">
									<el-form-item prop="year" label="西元" :rules="{required:true,message:'必填',trigger:'blur'}">
										<el-input v-model="item.year" :disabled="!item.editable" />
									</el-form-item>
									<el-form-item prop="title" label="標題">
										<el-input v-model="item.title" :disabled="!item.editable" placeholder="ex: { 金枝誕生 }" />
									</el-form-item>
									<el-form-item prop="chronicle" label="紀事" :rules="{required:true,message:'必填',trigger:'blur'}">
										<el-input v-model="item.chronicle" :disabled="!item.editable" type="textarea" :autosize="{minRows:3}" />
									</el-form-item>
								</el-form>
								<div class="col" align-self="bottom">
									<div v-if="item.editable" class="row">
										<el-button type="success" size="mini" @click="handleSave('timeline')">儲存</el-button>
										<el-button type="info" size="mini" @click="handleCancel('timeline')">取消</el-button>
									</div>
									<div v-else class="col" align-x="left">
										<el-button @click="$set(item, 'editable', true)" type="text" icon="el-icon-edit">編輯</el-button>
										<el-button @click="handleRemoveEvent(rangeKey, i)" icon="el-icon-delete" type="danger" size="mini" circle />
									</div>
								</div>
							</div>
						</el-card>
					</div>
				</div>
				<br>
				<el-button @click="handleAddEvent(rangeKey)" icon="el-icon-plus" type="success" round>新增紀事</el-button>
			</el-tab-pane>
		</el-tabs>
	</section>
</template>

<script>
import { mapActions, mapState } from "vuex"
export default {
	components: {
		Upload: () => import("@/components/upload")
	},
	data() {
		return {
			editable: new Object,
			banners1: new Array,
			banners1_mobile: new Array,
			banners2: new Array,
			banners2_mobile: new Array,
			banners3: new Array,
			banners3_mobile: new Array,
			timeline: new Object,
			popoverVisible1: false,
			tabToAdd: "",
			currentTab: "",
		}
	},
	computed: {
		...mapState("home", ["_home"]),
	},
	methods: {
		...mapActions("home", ["_getData", "_updateData"]),
		checkEditable(key) {
			const keys = Array.isArray(key) ? key : [key];
			return keys.every(k => this.editable[k]);
		},
		handleEdit(key) {
			const keys = Array.isArray(key) ? key : [key];
			keys.forEach(k => this.$set(this.editable, k, true));
		},
		async handleSave(key) {
			const keys = Array.isArray(key) ? key : [key];
			if (keys.includes("timeline")) this.timeline = this.handleSortTimeline(this.timeline);
			this.$message.success(await this._updateData(this._.pick(this.$data, keys)));
			keys.forEach(k => {
				this.$set(this.$data, k, this._.cloneDeep(this._home[k]));
				this.$set(this.editable, k, false);
			});
		},
		handleCancel(key) {
			if (this.$refs["ruleForm"] && this.$refs["ruleForm"].length) this.$refs["ruleForm"].forEach(form => form.resetFields());
			const keys = Array.isArray(key) ? key : [key];
			keys.forEach(k => {
				this.$set(this.$data, k, this._.cloneDeep(this._home[k]));
				this.$set(this.editable, k, false);
			});
		},
		async handleAddTab(tabName) {
			if (tabName) {
				this.$set(this.timeline, tabName, new Array);
				await this.handleSave("timeline");
				this.popoverVisible1 = false;
				this.currentTab = tabName;
				this.tabToAdd = "";
			}
		},
		handleCancelAddTab() {
			this.handleCancel("timeline");
			this.popoverVisible1 = false;
			this.tabToAdd = "";
		},
		handleRemoveTab(tabName) {
			this.$confirm(`${tabName}底下的資料將會被移除！`, `確認刪除${tabName}？`, { type: "warning" })
				.then(async () => {
					this.$delete(this.timeline, tabName);
					await this.handleSave("timeline");
					if (this.currentTab == tabName) this.currentTab = Object.keys(this.timeline)[0];
				});
		},
		handleRemoveEvent(key, index) {
			this.$confirm(`確認刪除？`, { type: "warning" })
				.then(async () => {
					this.timeline[key].splice(index, 1);
					await this.handleSave("timeline");
				});
		},
		handleAddEvent(key) {
			this.timeline[key].push({
				year: "",
				chronicle: "",
				editable: true
			});
		},
		handleSortTimeline(timeline_data) {
			for (const key in timeline_data) {
				if (timeline_data.hasOwnProperty(key)) {
					timeline_data[key] = timeline_data[key].sort((a, b) => a.year - b.year)
				}
			}
			return timeline_data;
		}
	},
	async created() {
		await this._getData();
		this._.assign(this.$data, this._.cloneDeep(this._home));
		this.currentTab = Object.keys(this.timeline)[0];
	}
}
</script>